import { createAsyncThunk } from "@reduxjs/toolkit";
import { getErrorMsg } from "../../common/ErrorMessages/errorMessage";
import { getPaymentHistoryList } from "../../services/paymentHistoryList.service";

export const getPaymentHistory = createAsyncThunk(
  "get/payment-history/list",
  async (val, { rejectWithValue }) => {
    try {
      const res = await getPaymentHistoryList(val);
      return res.data;
    } catch (err) {
      console.log("err: ", err);
      getErrorMsg(err);
      return rejectWithValue(err.response.data);
    }
  }
);
