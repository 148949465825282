import { createSlice } from "@reduxjs/toolkit";
import { getCurrentUser } from "./currentUser.thunk";

const initialState = {
  isLoading: false,
  currentuserdata: [],
  error: null,
};

const isVersionChange = (res) => {
  const user = JSON.parse(localStorage.getItem("userData"));

  if (user?.version !== res?.data?.version) {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("styleProfile");
    localStorage.removeItem("getDiscountCount");
    localStorage.removeItem("getRetryPaymentCount");
    localStorage.removeItem("redirectToCreateProject");
    localStorage.removeItem("version");

    localStorage.setItem("isHardRefresh", true);
    localStorage.removeItem("isOfflineBanner");
    localStorage.removeItem("isWhatsNewOpen");

    window.location.href = "/login";
  } else {
    return res;
  }
};

const currentUserSlice = createSlice({
  name: "currentuser",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currentuserdata = isVersionChange(action.payload);
      // state.currentuserdata = action.payload;

      state.error = null;
    });
    builder.addCase(getCurrentUser.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
      state.currentuserdata = [];
    });
  },
});

export default currentUserSlice.reducer;
