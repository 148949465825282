// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getProjects } from "./projectManagement.thunk";

const initialState = {
  projectList: { isFetching: false, data: [], error: null },
};

const ProjectManagementSlice = createSlice({
  name: "projectManagement",
  initialState,
  reducers: {
    loadingState: (state, action) => {
      // console.log("action: ", action.payload);
      state.isFetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getProjects.pending, (state) => {
      state.projectList.data = [];
      state.projectList.isFetching = true;
    });

    builder.addCase(getProjects.fulfilled, (state, action) => {
      state.projectList.isFetching = false;
      state.projectList.data = action?.payload;
      state.projectList.error = null;
    });

    builder.addCase(getProjects.rejected, (state, action) => {
      state.projectList.isFetching = false;
      state.projectList.error = action?.payload?.message;
    });
  },
});

export const { loadingState } = ProjectManagementSlice.actions;

export default ProjectManagementSlice.reducer;
