// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getTableColumnDefs } from "./tableColumnDefs.thunks";

const initialState = {
  tableColumnDefs: {
    isFetching: false,
    data: {},
    error: null,
    isApiCall: false,
  },
};

const TableColumnDefsSlice = createSlice({
  name: "tableColumnDefs",
  initialState,
  reducers: {
    updateTableColumnDefs: (state, action) => {
      state.tableColumnDefs.data = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTableColumnDefs.pending, (state) => {
      state.tableColumnDefs.data = {};
      state.tableColumnDefs.isFetching = true;
      state.tableColumnDefs.isApiCall = false;
    });

    builder.addCase(getTableColumnDefs.fulfilled, (state, action) => {
      state.tableColumnDefs.isFetching = false;
      state.tableColumnDefs.data = action?.payload;
      state.tableColumnDefs.error = null;
      state.tableColumnDefs.isApiCall = true;
    });

    builder.addCase(getTableColumnDefs.rejected, (state, action) => {
      state.tableColumnDefs.isFetching = false;
      state.tableColumnDefs.error = action?.payload?.message;
      state.tableColumnDefs.isApiCall = true;
    });
  },
});

export const { updateTableColumnDefs } = TableColumnDefsSlice.actions;

export default TableColumnDefsSlice.reducer;
