// // ** Redux Imports
// import { createSlice } from "@reduxjs/toolkit";
// import { getAllDataProjectManagement } from "./allDataProjectManagement.thunk";

// const initialState = {
//   allProjectList: {
//     isFetching: false,
//     data: {
//       requested: [],
//       footage_received: [],
//       assigned_to_editor: [],
//       editing_in_progress: [],
//       revision_in_progress: [],
//       edit_complete: [],
//       approved_by_client: [],
//     },
//     error: null,
//   },
// };

// const AllDataProjectManagementSlice = createSlice({
//   name: "allDataProjectManagement",
//   initialState,
//   extraReducers: (builder) => {
//     // styleProfileCreate
//     builder.addCase(getAllDataProjectManagement.pending, (state) => {
//       state.allProjectList.data = initialState.allProjectList.data;
//       state.allProjectList.isFetching = true;
//     });

//     builder.addCase(getAllDataProjectManagement.fulfilled, (state, action) => {
//       state.allProjectList.isFetching = false;
//       state.allProjectList.data = action?.payload;
//       state.allProjectList.error = null;
//     });

//     builder.addCase(getAllDataProjectManagement.rejected, (state, action) => {
//       state.allProjectList.isFetching = false;
//       state.allProjectList.error = action?.payload?.message;
//     });
//   },
// });

// export default AllDataProjectManagementSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import { getAllDataProjectManagement } from "./allDataProjectManagement.thunk";

const initialState = {
  allProjectList: {
    isFetching: {
      requested: false,
      footage_received: false,
      assigned_to_editor: false,
      editing_in_progress: false,
      revision_in_progress: false,
      edit_complete: false,
      approved_by_client: false,
    },
    data: {
      requested: [],
      footage_received: [],
      assigned_to_editor: [],
      editing_in_progress: [],
      revision_in_progress: [],
      edit_complete: [],
      approved_by_client: [],
    },
    error: {
      requested: null,
      footage_received: null,
      assigned_to_editor: null,
      editing_in_progress: null,
      revision_in_progress: null,
      edit_complete: null,
      approved_by_client: null,
    },
  },
};
export const checkAllFetched = (isFetching) => {
  return Object.values(isFetching).every((status) => status === false);
};

const AllDataProjectManagementSlice = createSlice({
  name: "allDataProjectManagement",
  initialState,

  reducers: {
    updateProjectData: (state, action) => {
      const { status, data } = action.payload;
      state.allProjectList.data[status] = data.data;
      state.allProjectList.error[status] = null;
    },
    updateProjectError: (state, action) => {
      const { status, error } = action.payload;
      state.allProjectList.error[status] = error;
    },
    updateFetchingStatus: (state, action) => {
      const { status, isFetching } = action.payload;
      state.allProjectList.isFetching[status] = isFetching;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getAllDataProjectManagement.pending, (state) => {
      state.allProjectList.isFetching = {
        ...state.allProjectList.isFetching,
      };
    });

    builder.addCase(getAllDataProjectManagement.fulfilled, (state) => {
      state.allProjectList.isFetching = {
        ...state.allProjectList.isFetching,
      };
    });

    builder.addCase(getAllDataProjectManagement.rejected, (state, action) => {
      console.log("state, action: ", state, action);
      // Handle general errors if needed
    });
  },
});

export const { updateProjectData, updateProjectError, updateFetchingStatus } =
  AllDataProjectManagementSlice.actions;

export default AllDataProjectManagementSlice.reducer;
