import axios from "axios";
import jwtDefaultConfig from "./jwtDefaultConfig";
import { AUTH_ROUTES, MAINTENANCE_ROUTE } from "../../../constant/route";

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig };

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false;
  axios = axios;
  // ** For Refreshing Token
  subscribers = [];

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig };

    // ** Request Interceptor
    axios.interceptors.request.use(
      (config) => {
        // ** Get token from localStorage
        const accessToken = this.getToken();
        config.baseURL = process.env.REACT_APP_BASENAME;

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // ** const { config, response: { status } } = error
        const { config, response } = error;
        const originalRequest = config;

        //------- if refresh token not valid or expire

        if (
          response &&
          response.status === 401 &&
          response.config.url === this.jwtConfig.refreshEndpoint
        ) {
          localStorage.removeItem(this.jwtConfig.storageTokenKeyName);
          localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName);
          localStorage.removeItem("userData");
          localStorage.removeItem("styleProfile");
          localStorage.removeItem("getDiscountCount");
          localStorage.removeItem("getRetryPaymentCount");
          localStorage.removeItem("redirectToCreateProject");

          localStorage.setItem("isHardRefresh", true);
          localStorage.removeItem("isOfflineBanner");
          localStorage.removeItem("isWhatsNewOpen");
          window.location = AUTH_ROUTES.APP_LOGIN;
        }

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true;
            this.refreshToken().then((r) => {
              this.isAlreadyFetchingAccessToken = false;

              // ** Update accessToken in localStorage

              this.setToken(r?.data?.data?.access_token);
              this.setRefreshToken(r?.data?.data?.refresh_token);

              this.onAccessTokenFetched(r?.data?.data?.access_token);
            });
          }
          const retryOriginalRequest = new Promise((resolve) => {
            this.addSubscriber((accessToken) => {
              // ** Make sure to assign accessToken according to your response.
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
              resolve(this.axios(originalRequest));
            });
          });
          return retryOriginalRequest;
        }

        if (response && response.status === 503) {
          window.location = MAINTENANCE_ROUTE;
        }

        // ** if (status === 401) {
        // if (response && response.status === 500) {
        //   toast((t) => (
        //     <ToastContent
        //       t={t.id}
        //       message="Server Error"
        //       icon={<X size={12} />}
        //       color="danger"
        //     />
        //   ));
        // if (!this.isAlreadyFetchingAccessToken) {
        //   this.isAlreadyFetchingAccessToken = true;
        //   this.refreshToken().then((r) => {
        //     this.isAlreadyFetchingAccessToken = false;

        //     // ** Update accessToken in localStorage
        //     this.setToken(r.data.accessToken);
        //     this.setRefreshToken(r.data.refreshToken);

        //     this.onAccessTokenFetched(r.data.accessToken);
        //   });
        // }
        // const retryOriginalRequest = new Promise((resolve) => {
        //   this.addSubscriber((accessToken) => {
        //     // ** Make sure to assign accessToken according to your response.
        //     // ** Change Authorization header
        //     originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`;
        //     resolve(this.axios(originalRequest));
        //   });
        // });
        //   return retryOriginalRequest;
        // }

        return Promise.reject(error);
      }
    );
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter((callback) =>
      callback(accessToken)
    );
  }

  addSubscriber(callback) {
    this.subscribers.push(callback);
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName);
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName);
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value);
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value);
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args);
  }

  adminlogin(...args) {
    return axios.post(this.jwtConfig.adminLogin, ...args);
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args);
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refresh_token: this.getRefreshToken(),
    });
  }
  reCaptcha(token) {
    const values = {
      token,
    };
    return axios.post(this.jwtConfig.reCaptchaEndpoint, values);
  }
}
