// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getDiscountCouponList } from "./discountCoupon.thunk";

const initialState = {
  discountCouponList: { isFetching: true, data: [], error: null },
};
const discountCouponSlice = createSlice({
  name: "discountCoupon",
  initialState,

  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getDiscountCouponList.pending, (state) => {
      state.discountCouponList.data = [];
      state.discountCouponList.isFetching = true;
    });

    builder.addCase(getDiscountCouponList.fulfilled, (state, action) => {
      state.discountCouponList.data = action?.payload;
      state.discountCouponList.isFetching = false;
      state.discountCouponList.error = null;
    });

    builder.addCase(getDiscountCouponList.rejected, (state, action) => {
      state.discountCouponList.isFetching = false;
      state.discountCouponList.error = action?.payload?.message;
    });
  },
});

export default discountCouponSlice.reducer;
