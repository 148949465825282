import authHttp from "./authHttp";

export const createStyleProfile = async (val) => {
  const url = "api/create-new/style-profile";
  const res = await authHttp.post(url, val);
  return res;
};

export const editStyleProfile = async (val) => {
  const url = "api/update/style-profile";
  const res = await authHttp.post(url, val);
  return res;
};

export const getStyleProfile = async (id) => {
  const url = `api/get-style-profile/style_profile/${id}`;
  const {
    data: { data },
  } = await authHttp.get(url);
  return data;
};

export const getProjectStyleProfile = async (id) => {
  const url = `api/get-style-profile/project/${id}`;
  const {
    data: { data },
  } = await authHttp.get(url);
  return data;
};

export const updateStyleProfileV2 = async (val) => {
  const url = "/api/update/existing-style-profile";
  const res = await authHttp.post(url, val);
  return res;
};

export const adminStyleProfile = async (val) => {
  const url = "api/admin-internal-notes";
  const res = await authHttp.post(url, val);
  return res;
};
