import { createSlice } from "@reduxjs/toolkit";

export const unreadCount = createSlice({
  name: "unreadCount",
  initialState: {
    adminUnreadCount: {},
    userUnreadCount: {},
  },
  reducers: {
    setAdminCount: (state, action) => {
      state.adminUnreadCount = action.payload;
    },

    setUserCount: (state, action) => {
      state.userUnreadCount = action.payload;
    },
  },
});

export const { setAdminCount, setUserCount } = unreadCount.actions;

export default unreadCount.reducer;
