// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import { getPaymentHistory } from "./paymentHistory.thunk";

const initialState = {
  paymentHistoryData: { isFetching: false, data: [], error: null },
};

const PaymentHistoryDataSlice = createSlice({
  name: "paymentHistory",
  initialState,
  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(getPaymentHistory.pending, (state) => {
      state.paymentHistoryData.data = [];
      state.paymentHistoryData.isFetching = true;
    });

    builder.addCase(getPaymentHistory.fulfilled, (state, action) => {
      state.paymentHistoryData.isFetching = false;
      state.paymentHistoryData.data = action?.payload;
      state.paymentHistoryData.error = null;
    });

    builder.addCase(getPaymentHistory.rejected, (state, action) => {
      state.paymentHistoryData.isFetching = false;
      state.paymentHistoryData.error = action?.payload?.message;
    });
  },
});

export default PaymentHistoryDataSlice.reducer;
