import authHttp from "./authHttp";

// export const getListDiscount = async (page) => {
//   const url = `/api/list-discount-coupon?page=${page}`;
//   const { data } = await authHttp.get(url);
//   return data;
// };
export const getListDiscount = async () => {
  const url = "/api/list-discount-coupon";
  const { data } = await authHttp.get(url);
  return data;
};

export const CreateDiscountCoupon = async (payload) => {
  const url = "/api/create-discount-coupon";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const EditDiscountCoupon = async (payload) => {
  const url = "/api/edit-discount-coupon";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const deleteDiscountCoupon = async (payload) => {
  const url = `/api/delete-discount-coupon`;
  const { data } = await authHttp.post(url, payload);
  return data;
};
