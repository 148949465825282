/* eslint-disable multiline-ternary */
// import { status } from "../views/admin/projectManagement";
// import { status } from "../views/admin/projectManagement";
import authHttp from "./authHttp";

export const getProjectListOld = async (payload) => {
  const { page, ...rest } = payload;
  const url = `/api/list-project?page=${page}`;
  const { data } = await authHttp.post(url, { ...rest });
  return data;
};

export const getProjectList = async (payload) => {
  const { ...rest } = payload;
  const url = `/api/project-list`;
  const { data } = await authHttp.post(url, { ...rest });
  return data;
};

export const getAllProjectList = async (payload) => {
  const { ...rest } = payload;
  const url = `/api/project-listing`;
  const { data } = await authHttp.post(url, { ...rest });
  return data;
};

export const getProjectPromiseAPI = async (payload) => {
  const url = `/api/project-listing`;
  // const url = `/api/v2/list-project`;
  // const url =
  //   payload === status.requested
  //     ? "/api/v2/list-project-v2"
  //     : `/api/project-listing`;
  const { data } = await authHttp.post(url, {
    user_id: "all",
    project_status: payload,
  });
  return data;
};

// export const getProjectPromiseAPI = async (payload) => {
//   if (typeof payload === "objec  t" && Array.isArray(payload)) {
//     const url = `/api/v2/list-project`;
//     const data = await Promise.all(
//       payload.map((item) => {
//         if (item?.value) {
// return authHttp.post(url, {
//   user_id: "all",
//   project_status: item?.value,
// });
//         }
//         return null;
//       })
//     );

//     const final_data = {};

//     data.forEach((val) => {
//       const project_data = val?.data?.data || [];
//       const project_status = JSON.parse(val?.config?.data);
//       if (project_status?.project_status) {
//         final_data[project_status?.project_status] = project_data;
//       }
//     });

//     return final_data;
//   }
//   return null;
// };
export const getProjectInfo = async (id) => {
  const url = `/api/fetch-project/retrive/${id}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const ChangeStatus = async (payload) => {
  const url = `/api/status-change?sub_project_id=${payload.id}`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const bulkStatusChange = async (payload) => {
  const url = `/api/bulk-status-change`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getFeedbacks = async (payload) => {
  const url = `/api/feedback/get?create_project_id=${payload.create_project_id}&sub_project_id=${payload.sub_project_id}`;
  const { data } = await authHttp.get(url);
  return data.data;
};

export const createFeedback = async (payload) => {
  const url = `/api/feedback/send`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const etaEstimationDate = async (payload) => {
  const url = `api/add-estimated-time`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const updateColumnSize = async (payload) => {
  const url = `/api/save-column-sizes`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

//

export const updateColumnOrder = async (payload) => {
  const url = `/api/save-column-orders`;
  const { data } = await authHttp.post(url, payload);
  return data;
};
// /api/get-custom-table/

export const getCustomTable = async (payload) => {
  const url = `/api/get-custom-table/${payload}`;
  const { data } = await authHttp.get(url);
  return data;
};

export const realtimeUnreadCount = async () => {
  const url = `/api/get-unread-counts`;
  const { data } = await authHttp.get(url);
  return data;
};

export const getUserInGroupChat = async (subId) => {
  const url = `/api/all-details/${subId}`;
  const { data } = await authHttp.get(url);
  return data;
};
