/* eslint-disable multiline-ternary */
import authHttp from "./authHttp";

// * old API
// export const getCaretStatementList = async (val) => {
//   const url = `api/list-caret?page=${val}`;
//   const { data } = await authHttp.get(url);
//   return data;
// };

export const getCaretStatementList = async (payload) => {
  const url = `api/list-caret`;
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const createCaret = async (payload) => {
  const url = "api/exchange-caret";
  const { data } = await authHttp.post(url, payload);
  return data;
};

export const getCaretStatementService = async () => {
  const url = "api/caret-statement";
  const { data } = await authHttp.get(url);
  return data?.data;
};
