import { createAsyncThunk } from "@reduxjs/toolkit";
import { getclientManagementListApi } from "../../services/clientManagement.service";
import toast from "react-hot-toast";
import { X } from "react-feather";
import ToastContent from "../../views/components/toast/toast";

export const getclientManagementList = createAsyncThunk(
  "get/clientManagement/list",
  async (val, { rejectWithValue }) => {
    try {
      const res = await getclientManagementListApi(val);
      return res;
    } catch (error) {
      console.log("error: ", error);
      toast((t) => (
        <ToastContent
          t={t.id}
          message={error?.response?.data?.message}
          color="danger"
          icon={<X size={12} />}
        />
      ));
      return rejectWithValue(error?.response?.data);
    }
  }
);
