/* eslint-disable object-curly-newline */
/* eslint-disable brace-style*/
/* eslint-disable block-spacing */

// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createStyleProfile,
  editStyleProfile,
  getProjectStyleProfile,
  getStyleProfile,
} from "../services/styleProfileServices";

// ** toast
import toast from "react-hot-toast";
import ToastContent from "../../src/views/components/toast/toast";
import { Check } from "react-feather";
import { USER_ROUTES } from "../constant/route";
import { getErrorMsg } from "../common/ErrorMessages/errorMessage";

export const styleProfileCreate = createAsyncThunk(
  "layout/createStyleProfile",
  async (val, { rejectWithValue }) => {
    try {
      const redirectToCreateProject = localStorage.getItem(
        "redirectToCreateProject"
      );
      const res = await createStyleProfile(val.value);
      localStorage.setItem("styleProfile", res?.data?.data?.id);
      toast((t) => (
        <ToastContent
          t={t.id}
          message={res?.data?.message}
          icon={<Check size={12} />}
        />
      ));

      if (redirectToCreateProject) {
        val.navigate(USER_ROUTES.CREATE_PROJECT);
        localStorage.setItem("getRetryPaymentCount", 0);
      } else {
        val.navigate(USER_ROUTES.APP_HOME);
      }

      return res.data.data;
    } catch (error) {
      getErrorMsg(error);
      return rejectWithValue(error?.response.data);
    }
  }
);

export const editProfileCreate = createAsyncThunk(
  "layout/editStyleProfile",
  async (val, { rejectWithValue }) => {
    try {
      const res = await editStyleProfile(val);
      localStorage.setItem("styleProfile", res?.data?.data?.id);
      toast((t) => (
        <ToastContent
          t={t.id}
          message={res?.data?.message}
          icon={<Check size={12} />}
        />
      ));
    } catch (error) {
      getErrorMsg(error);
      return rejectWithValue(error?.response.data);
    }
  }
);

export const fetchStyleProfile = createAsyncThunk(
  "layout/getStyleProfile",
  async (id) => {
    return await getStyleProfile(id);
  }
);

export const fetchProjectStyleProfile = createAsyncThunk(
  "layout/getProjectStyleProfile",
  async (id) => {
    return await getProjectStyleProfile(id);
  }
);

const initialState = {
  isLoading: false,
  styleProfileId: 0,
  styleProfile: { isFetching: true, data: [], error: null },
  error: null,
};
const styleProfileSlice = createSlice({
  name: "styleProfile",
  initialState,
  reducers: {
    paginationActions(state, action) {
      const { page, limit } = action.payload;
      state.page = page;
      state.rowPerPage = limit;
    },
  },

  extraReducers: (builder) => {
    // styleProfileCreate
    builder.addCase(styleProfileCreate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(styleProfileCreate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.styleProfileId = action.payload.id;
      state.error = null;
    });

    builder.addCase(styleProfileCreate.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    });

    // fetchStyleProfile
    builder.addCase(fetchStyleProfile.pending, (state) => {
      state.styleProfile.isFetching = true;
    });
    builder.addCase(fetchStyleProfile.fulfilled, (state, action) => {
      state.styleProfile.isFetching = false;
      state.styleProfile.data = action.payload;
      state.styleProfile.error = null;
    });

    builder.addCase(fetchStyleProfile.rejected, (state, action) => {
      state.styleProfile.isFetching = false;
      state.styleProfile.error = action.error?.message;
    });

    // editProfileCreate
    builder.addCase(editProfileCreate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(editProfileCreate.fulfilled, (state) => {
      state.isLoading = false;
      state.error = null;
    });

    builder.addCase(editProfileCreate.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload?.message;
    });

    // getProjectStyleProfile
    builder.addCase(fetchProjectStyleProfile.pending, (state) => {
      state.styleProfile.data = [];
      state.styleProfile.isFetching = true;
    });
    builder.addCase(fetchProjectStyleProfile.fulfilled, (state, action) => {
      state.styleProfile.isFetching = false;
      state.styleProfile.data = action.payload;
      state.styleProfile.error = null;
    });

    builder.addCase(fetchProjectStyleProfile.rejected, (state, action) => {
      state.styleProfile.isFetching = false;
      state.styleProfile.error = action.error?.message;
    });
  },
});

export const { paginationActions } = styleProfileSlice.actions;

export default styleProfileSlice.reducer;
