import { createSlice } from "@reduxjs/toolkit";

export const projectManagementStates = createSlice({
  name: "projectManagementStates",
  initialState: {
    loadingState: false,
    // dataState: null,
    cols: {},
    colDisable: false,
  },
  reducers: {
    setLoadingState: (state, action) => {
      state.loadingState = action.payload;
    },
    // setDataState: (state, action) => {
    //   state.dataState = action.payload;
    // },
    setColsState: (state, action) => {
      state.cols = action.payload;
    },

    setColDisable: (state, action) => {
      console.log("action.payload: ", action.payload);
      state.colDisable = action.payload;
    },
  },
});

export const { setLoadingState, setColsState, setColDisable } =
  projectManagementStates.actions;
// export const { setLoadingState, setDataState } =
//   projectManagementStates.actions;

export default projectManagementStates.reducer;
