import { createAsyncThunk } from "@reduxjs/toolkit";
import { getProjectPromiseAPI } from "../../services/projectManagementService";
import {
  updateFetchingStatus,
  updateProjectData,
  updateProjectError,
} from "./allDataProjectManagement";

// export const getAllDataProjectManagement = createAsyncThunk(
//   "get/projects/list",
//   async (payload, { rejectWithValue }) => {
//     try {
//       const res = await getProjectPromiseAPI(payload);
//       return res;
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

//! Sequential API Calls ------------------------------------------------------------------------------------------
// import {
//   updateProjectData,
//   updateProjectError,
//   updateFetchingStatus,
// } from "./allDataProjectManagement.slice";

// export const getAllDataProjectManagement = createAsyncThunk(
//   "get/projects/list",
//   async (payload, { dispatch, rejectWithValue }) => {
//     try {
//       for (const status of payload) {
//         dispatch(updateFetchingStatus({ status, isFetching: true }));
//         try {
//           const res = await getProjectPromiseAPI(status);
//           dispatch(updateProjectData({ status, data: res }));
//           return { status, success: true };
//         } catch (err) {
//           dispatch(updateProjectError({ status, error: err.response.data }));
//           return { status, success: false, error: err.response.data };
//         } finally {
//           dispatch(updateFetchingStatus({ status, isFetching: false }));
//         }
//       }
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

//! Parallel API Calls ------------------------------------------------------------------------------------------
// export const getAllDataProjectManagement = createAsyncThunk(
//   "get/projects/list",
//   async (payload, { dispatch, rejectWithValue }) => {
//     try {
//       const promises = payload.map(async (status) => {
//         dispatch(updateFetchingStatus({ status, isFetching: true }));

//         try {
//           const res = await getProjectPromiseAPI(status);
//           dispatch(updateProjectData({ status, data: res }));
//           return { status, success: true };
//         } catch (err) {
//           dispatch(updateProjectError({ status, error: err.response.data }));
//           return { status, success: false, error: err.response.data };
//         } finally {
//           dispatch(updateFetchingStatus({ status, isFetching: false }));
//         }
//       });
//       await Promise.allSettled(promises);
//     } catch (err) {
//       return rejectWithValue(err.response.data);
//     }
//   }
// );

//! Parallel API Calls With delay -----------------------------------------------------------------------------------

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const getAllDataProjectManagement = createAsyncThunk(
  "get/projects/list",
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const promises = payload.map(async (status, index) => {
        await delay(index === 0 ? index : (index - 1) * 100);
        dispatch(updateFetchingStatus({ status, isFetching: true }));

        try {
          const res = await getProjectPromiseAPI(status);
          dispatch(updateProjectData({ status, data: res }));
        } catch (err) {
          dispatch(updateProjectError({ status, error: err.response.data }));
        } finally {
          dispatch(updateFetchingStatus({ status, isFetching: false }));
        }
      });
      await Promise.allSettled(promises);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const loadingProjectManagement = createAsyncThunk(
  "get/projects/list",
  ({ status, isFetching }, { dispatch }) => {
    status.map((val) => {
      dispatch(updateFetchingStatus({ status: val, isFetching }));
    });
  }
);
